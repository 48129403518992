import type { AnyObject, ArraySchema, Schema, StringSchema } from "yup";
import { array, object, ref as yubRef, string } from "yup";

interface FieldSchema_t {
  fieldRequired: (field: string) => StringSchema;
  fieldEmail: (field: string) => StringSchema;
  fieldPassword: (field: string) => StringSchema;
  fieldPhone: (field: string, min: number, max: number) => StringSchema;
  fieldFixLength: (field: string, min: number, max: number) => StringSchema;
  fieldArray: (
    opts: Record<string, StringSchema | any>
  ) => ArraySchema<Record<string, string>[] | undefined, AnyObject, "", "">;
  fieldConfirm: (field: string, key: string[] | string) => StringSchema;
}

export const useFieldSchema = (): FieldSchema_t => {
  const fieldRequired = (field = "ข้อมูล"): StringSchema =>
    string().trim().required(`กรุณากรอก${field}`);
  const fieldEmail = (field = "ข้อมูล"): StringSchema =>
    fieldRequired(field).email(`รูปแบบ${field}ไม่ถูกต้อง`);
  const fieldPassword = (field = "รหัสผ่าน"): StringSchema =>
    fieldRequired(field)
      .min(6, `${field}ต้องมีอย่างน้อย 6 ตัวอักษร`)
      .max(32, `${field}ต้องมีไม่เกิน 32 ตัวอักษร`);

  const fieldConfirm = (
    field = "ยืนยันรหัสผ่าน",
    key: string[] | string
  ): StringSchema => {
    const settingArrayOfValues = Array.isArray(key)
      ? key.map((i: string) => yubRef(i))
      : [yubRef(key)];
    return fieldRequired(field).oneOf(
      settingArrayOfValues,
      `${field}ไม่ถูกต้อง`
    );
  };
  const fieldFixLength = (field = "ข้อมูล", min = 4, max = 8): StringSchema => {
    return fieldRequired(field)
      .min(min, `${field}ต้องมีอย่างน้อย ${min} ตัวอักษร`)
      .max(max, `${field}ต้องมีไม่เกิน ${max} ตัวอักษร`);
  };
  const fieldPhone = (
    field = "เบอร์โทรศัพท์",
    min = 10,
    max = 10
  ): StringSchema =>
    fieldRequired(field)
      .matches(/[0-9]/, {
        message: `${field}ต้องเป็นตัวเลขเท่านั้น`,
      })
      .min(min, `${field}ต้องมีอย่างน้อย ${min} หลัก`)
      .max(max, `${field}ต้องมีไม่เกิน ${max} หลัก`);
  const fieldArray = (
    opts: Record<string, Schema>
  ): ArraySchema<Record<string, string>[] | undefined, AnyObject, "", ""> =>
    array().of(object(opts));

  return {
    fieldRequired,
    fieldEmail,
    fieldArray,
    fieldPassword,
    fieldPhone,
    fieldConfirm,
    fieldFixLength,
  };
};
